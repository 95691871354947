import React from 'react';

const Home = () => {
  return (
    <div>
      Placeholder
    </div>
  )
};

export default Home;