import { Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import Chilis from "./pages/chilis";
import Chor from "./pages/chor";

const AppRoutes = () => {
  return (
    <Routes>

      <Route
        exact path="/"
        element={<Home />}
      />
    
      <Route 
        path="/chilis"
        element={<Chilis />}
      />      

      <Route 
        path="/chor"
        element={<Chor />}
      />      

    </Routes>
  );
};

export default AppRoutes;