import React, {useState} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row
} from 'react-bootstrap';

import { chorPW } from "../../stuff/chorPw";
import finanzen from "../../imgs/chor/Finanzen.pdf";





const Chor = () => {

  // =====================================================================================================================
  // Password Checking for displaying the whole subsite content 
  const [hasAccess, setHasAccess] = useState(false);

  function checkPassword() {
    const typedPassword = document.getElementById("password").value;

    if (typedPassword === chorPW) {
      setHasAccess(true);
    } else {
      alert("Doesn't seem like it");
    }
  };
  // =====================================================================================================================

  if (!hasAccess) {
    return (
      <Card className="m-4">
        <Card.Header>
          Do you have access here? :)
        </Card.Header>
        <Card.Body className="p-4">
          <Row className="d-flex align-items-center">
            <Col xs={3}>
              <Form>
                <Form.Group className="m-3" controlId="password">
                  <Form.Control type="password" placeholder="Type Password here" />
                </Form.Group>
              </Form>
            </Col>

            <Col>
              <Button
                variant="secondary"
                onClick={ () => {
                  checkPassword();
                }}
              >
                Enter
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  } else return (
    <Card className="m-4">
      <Card.Header>
        kleiner Hub für all unsere Chor Sachen :)
      </Card.Header>

      <Card.Body className='p-4'>

        <h3>Finances</h3>

        <iframe src={ finanzen } width="80%" height="800" title="finances.pdf"/>
        
      </Card.Body>
    </Card>
  )
};

export default Chor;