import React from "react";

import { Link } from "react-router-dom";

import {
  Nav,
  Navbar,
  NavLink
} from 'react-bootstrap';



// Here, we display our Navbar
const MyNavbar = () => {
  return (
    <Navbar>    

      <Nav>
        <NavLink eventKey="1" as={Link} to="/">
          Home
        </NavLink>
        <NavLink eventKey="2" as={Link} to="/chilis">
          Chilis
        </NavLink>
        <NavLink eventKey="3" as={Link} to="/chor">
          Chor
        </NavLink>
      </Nav>

    </Navbar>
  );
};

export default MyNavbar;