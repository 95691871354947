import React from 'react';

import {
  Card,
  Carousel,
  Col,
  Image,
  Row
} from 'react-bootstrap';

import image2301 from "../../imgs/chilis/2023/20230810_091532.jpg";
import image2302 from "../../imgs/chilis/2023/20230810_091619.jpg"
import image2303 from "../../imgs/chilis/2023/20230810_091758.jpg"
import image2304 from "../../imgs/chilis/2023/20230818_144649.jpg"
import image2305 from "../../imgs/chilis/2023/20230822_175636.jpg"
import image2306 from "../../imgs/chilis/2023/20230822_175644.jpg"
import image2307 from "../../imgs/chilis/2023/20230822_175649.jpg"
import image2308 from "../../imgs/chilis/2023/20230822_175703.jpg"
import image2309 from "../../imgs/chilis/2023/20230822_175707.jpg"
import image2310 from "../../imgs/chilis/2023/20230913_191132.jpg"
import image2311 from "../../imgs/chilis/2023/20230913_191139.jpg"
import image2312 from "../../imgs/chilis/2023/20230913_191144.jpg"
import image2313 from "../../imgs/chilis/2023/20230913_191155.jpg"
import image2314 from "../../imgs/chilis/2023/20230913_191234.jpg"
import image2315 from "../../imgs/chilis/2023/20230920_173850.jpg"
import image2316 from "../../imgs/chilis/2023/20230922_123020.jpg"

import image2401 from "../../imgs/chilis/2024_giveaway/photo_1.jpg"
import image2402 from "../../imgs/chilis/2024_giveaway/photo_2.jpg"
import image2403 from "../../imgs/chilis/2024_giveaway/photo_3.jpg"
import image2404 from "../../imgs/chilis/2024_giveaway/photo_4.jpg"
import image2405 from "../../imgs/chilis/2024_giveaway/photo_5.jpg"
import image2406 from "../../imgs/chilis/2024_giveaway/photo_6.jpg"
import image2407 from "../../imgs/chilis/2024_giveaway/photo_7.jpg"
import image2408 from "../../imgs/chilis/2024_giveaway/photo_8.jpg"
import image2409 from "../../imgs/chilis/2024_giveaway/photo_9.jpg"
import image2410 from "../../imgs/chilis/2024_giveaway/photo_10.jpg"
import image2411 from "../../imgs/chilis/2024_giveaway/photo_11.jpg"
import image2412 from "../../imgs/chilis/2024_giveaway/photo_12.jpg"
import image2413 from "../../imgs/chilis/2024_giveaway/photo_13.jpg"
import image2414 from "../../imgs/chilis/2024_giveaway/photo_14.jpg"
import image2415 from "../../imgs/chilis/2024_giveaway/photo_15.jpg"


function getCarousel(images) {
  return (
    <Carousel
      data-bs-theme="dark"
      interval={2000}
      pause='hover'
      wrap={true}
      touch={true}
    >
      {images.map((image) => {
        return (
          <Carousel.Item>
            <Row className='d-flex justify-content-center'>
              <Image
              className='pb-4 mb-4'
                style={{'height' : '40vh', 'width': 'auto'}}
                src={image}
                alt="Image of Chili"
              />
            </Row>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}


function showChilis2024giveAway(images) {
  return (
    <Row>
      <Col>
        This is an overview for the chili plants that are available.<br/><br/>

        {getCarousel(images)}

        <br/>
        Specifically the available plants are:<br/>
        - Apocalypse Orange <a href="https://www.kk-stauden.de/product_info.php?products_id=793">see more</a><br/>
        - Apocalypse Red <a href="https://www.chili-shop24.de/chili-zucht/chili-samen/schaerfegrad-10/11991/apocalypse-red-chili-samen">see more</a><br/>
        - Bolivian Bumpy <a href="https://www.chili-shop24.de/chili-zucht/chili-samen/schaerfegrad-9-10/2968/bolivian-bumpy-chili-samen">see more</a><br/>
        - Bolivian Rainbow <a href="https://www.pepperworldhotshop.com/de/produkte/bolivian-rainbow-chilisamen/">see more</a><br/>
        - Carolina Reaper <a href="https://www.pepperworldhotshop.com/de/sorten/carolina-reaper/">see more</a><br/>
        - Chinese Flame <a href="https://www.pepperworldhotshop.com/versand-samen/chinese-flame-chilisamen/">see more</a><br/>
        - Habanero Orange <a href="https://www.chili-shop24.de/chili-zucht/chili-samen/schaerfegrad-9-10/2997/habanero-orange-chili-samen">see more</a><br/>
        - Italian Long Hot Pepper <a href="https://www.chilipeppermadness.com/chili-pepper-types/italian-long-hot-peppers/">see more</a><br/>
        - Jalapeno <a href="https://www.pepperworldhotshop.com/de/sorten/jalapeno/">see more</a><br/>
        - Lemon Drop <a href="https://www.pepperworldhotshop.com/de/sorten/lemon-drop/bio-hot-lemon-chilisamen/">see more</a><br/>
        - Peperoncino Piccante <a href="https://www.chili-shop24.de/chili-zucht/chili-samen/schaerfegrad-4-6/4428/peperoncini-piccante-guarda-cielo-chili-samen">see more</a><br/>
        - Rawit/Thai <a href="https://deaflora.de/Shop/Chili/Chili-Rawit.html?language=de">see more</a><br/>
        - Scorpion Peach <a href="https://www.chili-shop24.de/chili-zucht/chili-samen/schaerfegrad-10/11968/trinidad-scorpion-moruga-peach-chili-samen">see more</a><br/>
        - Scotch Bonnet <a href="https://www.scovilla.com/de/chilisamen/samen/1002/scotch-bonnet-red-chili-samen">see more</a><br/>
        - Sugar Rush Stripe <a href="https://deaflora.de/Shop/Chili/Chili-Sugar-Rush-Stripey.html?language=de">see more</a><br/>
      </Col>
    </Row>
  )
}


function showChilis2023(images) {
  return (
    <Row>
      <Col>
        This is a small look into my chili garden from the summer season of 2023.<br/>
        Most of these plants found a new home at the end of the season due to a plant gifting event I hosted :)<br/><br/>
        
        {getCarousel(images)}

        <br/>
      </Col>
    </Row>
  )
}




const Chilis = () => {

  const images_2023 = [
    image2301, image2302, image2303, image2304,
    image2305, image2306, image2307, image2308,
    image2309, image2310, image2311, image2312,
    image2313, image2314, image2315, image2316
  ]

  const images_2024giveaway = [
    image2401, image2402, image2403, image2404,
    image2405, image2406, image2407, image2408,
    image2409, image2410, image2411, image2412,
    image2413, image2414, image2415
  ]


  return (
    <div>
      <Card className='m-4'>
        <Card.Header>
          Welcome to the chili page :3
        </Card.Header>

        <Card.Body>

          {showChilis2024giveAway(images_2024giveaway)}

          <div className='border-top mt-4'>&nbsp;</div>

          {showChilis2023(images_2023)}

        </Card.Body>
      </Card>
    </div>
  )
};

export default Chilis;