import React from "react";

import MyNavbar from "./components/layout/Navbar";
 
import AppRoutes from "./components/routes";

 
function App() {
  return (
    <div>
      <MyNavbar />
      <AppRoutes />
    </div>
  );
};
 
export default App;